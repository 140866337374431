<template>
  <transition name="modal">
    <div :class="['modal', 'modal-' + mask]" @click="click">
      <div class="modal-container" @click.stop>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    mask: { type: String, default: 'dark' }
  },
  data() {
    return {};
  },
  methods: {
    click(ev) {
      this.$emit('click', ev);
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: opacity 0.3s ease;
  align-items: center;
  justify-content: center;
}

.modal-dark {
  background-color: #0008;
}

.modal-light {
  background-color: #fff8;
}

.modal-container {
  /* min-width: 50%; */
  max-width: 90%;
  max-height: 90vh;
  background-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}
</style>
